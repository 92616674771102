import RevealController from './reveal';
import TrackingOptOutController from './tracking-opt-out';

export const controllerDefinitions = [
  {
    controllerConstructor: RevealController,
    identifier: 'reveal',
  },
  {
    controllerConstructor: TrackingOptOutController,
    identifier: 'tracking-opt-out',
  },
];
