import { createFocusTrap } from 'focus-trap';
import { Controller } from '@hotwired/stimulus';

/**
 * Toggle the visibility - through its CSS classes - of a container.
 *
 * @example - toggle a popup main menu
 * <button type="button" aria-controls="main-menu">
 *   Toggle main menu
 * </button>
 * <div
 *   id="main-menu"
 *   class="invisble"
 *   aria-modal="true"
 *   role="dialog"
 *   data-controller="reveal"
 *   data-action="keydown.esc->reveal#hide"
 *   data-reveal-opened-class="!visible"
 *   data-reveal-opened-body-class="overflow-y-hidden"
 *   data-reveal-trap-focus-value="true"
 * >
 *   <p>Some content</p>
 *   <button type="button" data-action="reveal#hide">
 *     Close
 *   </button>
 * </div>
 */
export default class extends Controller {
  static classes = ['hidden', 'opened', 'openedBody'];

  static values = {
    opened: { tyoe: Boolean, default: false },
    trapFocus: { type: Boolean, default: false },
  };

  // Callbacks

  connect() {
    if (this.trapFocusValue) {
      this.focusTrap = createFocusTrap(this.element);
    }

    this.body = document.querySelector('body');
    this.togglers = document.querySelectorAll(
      `[aria-controls~="${this.element.id}"]`,
    );

    this.togglers.forEach((element) => {
      element.addEventListener('click', this.toggle.bind(this));
    });
  }

  disconnect() {
    if (this.focusTrap) {
      this.focusTrap.deactivate();
    }

    this.togglers.forEach((element) => {
      element.removeEventListener('click', this.toggle.bind(this));
    });
  }

  openedValueChanged(value, previousValue) {
    // Skip if this is the initial state or if the state didn't changed
    if (previousValue === undefined || value === previousValue) return;

    if (value) {
      this.element.classList.remove(...this.hiddenClasses);
      this.element.classList.add(...this.openedClasses);

      this.body.classList.add(...this.openedBodyClasses);

      if (this.focusTrap) {
        this.focusTrap.activate();
      } else {
        this.element.focus();
      }

      this.togglers.forEach((element) => {
        element.setAttribute('aria-expanded', 'true');
      });
    } else {
      this.element.classList.remove(...this.openedClasses);
      this.element.classList.add(...this.hiddenClasses);

      this.body.classList.remove(...this.openedBodyClasses);

      if (this.focusTrap) {
        this.focusTrap.deactivate();
      }

      this.togglers.forEach((element) => {
        element.setAttribute('aria-expanded', 'false');
      });
    }
  }

  // Actions

  toggle() {
    this.openedValue = !this.openedValue;
  }

  show() {
    this.openedValue = true;
  }

  hide() {
    this.openedValue = false;
  }
}
